import { find, get } from 'lodash'
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters([
      'currencyConfig',
      'locale'
    ]),
    symbol () {
      return get(find(this.currencyConfig, { alias: this.locale }), 'symbol')
    },
    price () {
      return get(find(this.currencyConfig, { alias: this.locale }), 'price')
    },
    expressFee () {
      return get(find(this.currencyConfig, { alias: this.locale }), 'expressFee')
    },
    currency () {
      return get(find(this.currencyConfig, { alias: this.locale }), 'currency')
    },
    groupPrice () {
      return get(find(this.currencyConfig, { alias: this.$store.state.locale }), 'groupPrice')
    },
    expressTime () {
      return find(this.currencyConfig, { alias: this.locale }).expressTime
    }
  },
  methods: {
    getSymbol (currency) {
      return get(find(this.currencyConfig, { currency }), 'symbol')
    },
    getSymbolByLocale (locale) {
      return get(find(this.currencyConfig, { alias: locale }), 'symbol')
    }
  }
}
