<template>
  <section class="product-templates">
    <header>
      <h2>版式設計</h2>
      <p class="subtitle is-hidden-mobile">
        你、我、他都各有偏愛，所以我們準備了A5瀑布流版、A5雙欄版、A4瀑布流排版
        不管哪一種排版，記錄時光都是我們不變的初心
      </p>
    </header>
    <div class="container">
      <div class="row">
        <div class="col-xs-4" v-for="(item, index) in templates" :key="index">
          <div class="product-templates-item">
            <cover-holder :height="height" :src="item.cover" />
            <div class="product-templates-item-info">
              <h4>{{item.label}}</h4>
              <p>更大圖片，更多細節，圖文排版堪比寫真</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import CoverHolder from '../CoverHolder'
export default {
  name: '',
  components: { CoverHolder },
  data: () => ({
    templates: [
      {
        name: 'a5-waterfall',
        label: 'A5 瀑布流',
        cover: require('../../../static/images/product/templates-1.png')
      },
      {
        name: 'a5-column',
        label: 'A5 雙欄',
        cover: require('../../../static/images/product/templates-2.png')
      },
      {
        name: 'a4-waterfall',
        label: 'A4 瀑布流',
        cover: require('../../../static/images/product/templates-3.png')
      }
    ],
    height: 77
  }),
  computed: {},
  created () {},
  methods: {}
}
</script>

<style type="text/scss" lang="scss">
.product-templates {
  &-item {
    border-radius: 4px;
    overflow: hidden;
    text-align: center;
    box-shadow: 0px 12px 40px 0px rgba(175,175,175,0.2);
    &-info {
      font-size: 14px;
      padding: 1em;
    }
  }
}
</style>
