<template>
  <section class="product-prices">
    <header>
      <h2>價格</h2>
    </header>
    <div class="text-center">
    <!--   eslint-disable   -->
      <div class="product-prices-table">
        <div class="row is-mobile first-line">
          <div class="col-xs-2 col-xs-offset-1">裝幀</div>
          <div class="col-xs-2">A5軟裝</div>
          <div class="col-xs-2">A5精裝</div>
          <div class="col-xs-2">A4軟裝</div>
          <div class="col-xs-2">A4精裝</div>
        </div>
        <div class="row is-mobile">
          <div class="col-xs-2 col-xs-offset-1">內頁價格</div>
          <template v-if="prices">
            <div class="col-xs-2 content">{{symbols}}{{prices['a5']['paperback'].inner}}</div>
            <div class="col-xs-2 content">{{symbols}}{{prices['a5']['hardback'].inner}}</div>
            <div class="col-xs-2 content">{{symbols}}{{prices['a4']['paperback'].inner}}</div>
            <div class="col-xs-2 content">{{symbols}}{{prices['a4']['hardback'].inner}}</div>
          </template>
          <template v-else>
            <div class="col-xs-2" v-for="(item, index) in 4" :key="index">
              加载中...
            </div>
          </template>
        </div>
        <div class="row">
          <div class="col-xs-2 col-xs-offset-1">內頁材質</div>
          <div class="col-xs-2 content">啞光進口東方書紙</div>
          <div class="col-xs-2 content">啞光水晶超感</div>
          <div class="col-xs-2 content">啞光進口東方書紙</div>
          <div class="col-xs-2 content">啞光水晶超感</div>
        </div>
        <div class="row is-mobile">
          <div class="col-xs-2 col-xs-offset-1">內 包 裝</div>
          <div class="col-xs-2 content">創意布袋</div>
          <div class="col-xs-2 content">精美書盒</div>
          <div class="col-xs-2 content">創意布袋</div>
          <div class="col-xs-2 content">創意布袋</div>
        </div>
        <div class="row is-mobile">
          <div class="col-xs-2 col-xs-offset-1">外 包 裝</div>
          <div class="col-xs-2 content">A5飛機盒</div>
          <div class="col-xs-2 content">A5飛機盒</div>
          <div class="col-xs-2 content">A4飛機盒</div>
          <div class="col-xs-2 content">A4飛機盒</div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: '',
  props: {
    prices: Object,
    symbols: String
  },
  data: () => ({
  }),
  computed: {},
  created () {},
  methods: {}
}
</script>

<style type="text/scss" lang="scss">
.product-prices {
  &-table {
    .row.first-line {
      .col-xs-2 {
        background-color: #FFEEEE;
      }
    }
    .col-xs-2 {
      padding: .75rem 0;
      border: 1px solid #FFEEEE;
      &.content {
        font-weight: 500;
      }
    }
    .col-xs-2 + .col-xs-2 {
      border-left: 1px solid #FFEEEE;
    }
  }
}
</style>
