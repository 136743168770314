<template>
  <section class="product-design">
    <header>
      <h2>{{design.title}}</h2>
      <p class="subtitle is-hidden-mobile">
        {{design.subtitle}}
      </p>
    </header>
    <div class="container text-center">
      <img v-lazy="design.image" class="hidden-xs" alt="" draggable="false">
      <img v-lazy="design.imageMobile" class="visible-xs" alt="" draggable="false">
    </div>
  </section>
</template>

<script>
export default {
  name: 'ProductDesign',
  props: {
    design: Object
  },
  data: () => ({
  }),
  computed: {},
  created () {},
  methods: {}
}
</script>

<style type="text/scss" lang="scss">
.product-design {

}
</style>
